import { compose, test, splitEvery, join, cond, T, curry, lt, ifElse } from 'ramda';
import { isEmail, isCreditCard, isPostalCode, isCurrency } from 'validator';
import { isAfter } from 'date-fns';
import { stubNull } from 'ramda-adjunct';
import { FormArray, FormGroup } from '@angular/forms';

import { get } from './form.helpers';
import { nilArgs } from './null.helpers';
import { toFloat } from './number.helpers';
import { valueCode } from './code.helpers';
import { unequal } from './function.helpers';
import { isValidTopLevel } from './top-level.helpers';
import { useNumericValues, useValues, value } from './value.helpers';
import { validatorError, hoursbyPeriod, setValidationError, compareControls } from './validation.helpers';
import { before30Days, before120Years, before60Days, after10Months, beforeSecondMonth, withInNineMonths, vehicleYearBetween, invalidFutureDays, before5Months, before1Year, invalidTodayDate, before90Days } from './date.helpers';

import { ErrorCodes } from '@app/models/errors.model';

const isStartAfterEnd = ifElse(nilArgs, stubNull, isAfter);

// {6,100}           - Assert password is between 6 and 100 characters
// (?=.*[0-9])       - Assert a string has at least one number
const isPassword = test(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/);
const isValidName = test(/^(?!\s)[a-zA-Z0-9\s-./'’]{1,100}$/);
const isValidCompanyName = test(/^[a-zA-Z0-9\s-./'’]{1,100}$/);
const isValidInsuranceCompanyName = test(/^(?!\s)[a-zA-Z0-9\s-,./'’]{1,100}$/);
const isValidSignatureName = test(/^(?!\s)[a-zA-Z0-9\s-.'’]{1,100}$/);
const initialSpace = test(/^(?!\s)/);
const isHouseNumber = compose(
  test(/^[a-zA-Z0-9\s/]*$/),
  String
);

const isExpense = compose(
  isCurrency,
  toFloat
);

const isUnit = compose(
  test(/^[a-zA-Z0-9 \/]*$/),
  String
);

// Number Validator
const isNumber = compose(
  test(/^-?(0|[1-9]\d*)?$/),
  String
);

// EmployerName Validator
const isValidEmployerName = compose(
  test(/^(?!\s)[a-zA-Z0-9\s-' .,/&]*$/),
  String
);



const isStreetAddress = compose(
  test(/^(?!\s)[a-zA-Z0-9\s-' .,/&#]*$/),
  String
);

// All characters except: ^, #, %, *, { }, [ ], < >, |
const isComments = compose(
  test(/^[^#%*{}<>|[\]^]*$/),
  String
);

const isSubmittedBy = compose(
  test(/^[^#%*{}<>|[\]^]*$/),
  String
);

const isZipCode = compose(
  zip => isPostalCode(zip, 'US'),
  join('-'),
  splitEvery(5)
);

const isCity = compose(
  test(/^(?!\s)[a-zA-Z\s]*$/),
  String
);

const isLanguage = compose(
  test(/^(?!\s)[a-zA-Z\s]*$/),
  String
);

const isMedicareNumber = compose(
  test(/^[a-zA-Z0-9{]*$/),
  String
);

// const isInvalidField = compose(
//   test(/^[a-zA-Z0-9-' .,/&@"]*$/),
//   String
// );

const isValidDate = compose(
  test(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/),
  String
);

const isEmailAddress = compose(
  isEmail,
  String
);

const isValidPhNumber = compose(
  test(/[1-9]{1}[0-9]{2}[1-9]{1}[0-9]{2}[0-9]{4}/),
  String
);
const isAlien = test(/^\d{9}$/);
const isSevis = test(/^\d{10}/);
const isCard = test(/^[a-zA-Z]{3}\d{10}/)
const isI94 = test(/^[a-zA-Z0-9]{11}$/);
const isDocDecription = test(/^[a-zA-Z0-9.' \/-]*$/);

const iscitizenshipNumber = test(/^[a-zA-Z0-9]{6,12}$/);

const isAlphaNumeric = test(/^[a-z0-9]+$/i);
const isAlphaNumericHyphen = test(/^[a-z0-9-]+$/i);
const orgName = test(/^[a-z0-9 ]+$/i);
const currency = test(/^[0-9]\d*(\.\d+)?$/);
// const isPassport = test(/^([a-zA-Z0-9]){20,}$/);
const isPassport = test(/^[a-zA-Z0-9]{1,20}$/);

const numeric = test(/^[0-9]*(\.[0-9]{0,2})?$/)
const isSplCharacter = test(/^[A-Za-z0-9]+$/);
const isUserNameEmail = test(/^[A-Za-z0-9]+$/);
const isWithinLength = test(/^.{6,20}$/);
const noSpace = test(/^\s-/);
const isWithinAnswerLength = test(/^.{3,}$/)

const noDecimal = test(/^[0-9]*$/);
const percentage = (value) => value <= 100;
const isValidFirstLastName = test(/^[^-\s]([A-Za-z- '])*$/);
const isValidSSN = test(/^\d{3}(?!00)\d{2}\d{4}$/);
const isPersonId = test(/^[0-9]{9,10}$/);

export const creditCardValidator = validatorError(isCreditCard, ErrorCodes.INVALID_CREDITCARD);
export const emailValidator = validatorError(isEmailAddress, ErrorCodes.INVALID_EMAIL_ADDRESS);
export const passwordValidator = validatorError(isPassword, ErrorCodes.INVALID_PASSWORD);
export const expenseValidator = validatorError(isExpense, ErrorCodes.INVALID_EXPENSE);
export const numericValidator = validatorError(numeric, ErrorCodes.INVALID_NUMERIC);
export const percentageValidator = validatorError(percentage, ErrorCodes.INVALID_PRECENTAGE);
export const individualNameValidator = validatorError(isValidName, ErrorCodes.INVALID_NAME);
export const employerNameValidator = validatorError(isValidEmployerName, ErrorCodes.INVALID_EMPLOYER_NAME);
export const noInitialSpaceValidator = validatorError(initialSpace, ErrorCodes.required)
export const firstNameValidator = validatorError(isValidFirstLastName, ErrorCodes.INVALID_FIRST);
export const lastNameValidator = validatorError(isValidFirstLastName, ErrorCodes.INVALID_LAST);
export const otherExpenseValidator = validatorError(isValidName, ErrorCodes.INVALID_OTHER_EXPENSE);
export const nameValidator = validatorError(isValidName, ErrorCodes.INVALID_TAX_NAME);
export const companyNameValidator = validatorError(isValidCompanyName, ErrorCodes.INVALID_TAX_NAME);
export const insuranceCompanyNameValidator = validatorError(isValidInsuranceCompanyName, ErrorCodes.INVALID_INSURANCE_COMPANY_NAME);
export const nursingNameValidator = validatorError(isValidName, ErrorCodes.INVALID_NURSING_NAME);
export const descriptionValidator = validatorError(isValidName, ErrorCodes.INVALID_DESCRIPTION);
export const policyNumberValidator = validatorError(isAlphaNumeric, ErrorCodes.INVALID_ALPHANUMERIC_CHARACTER);
export const safeAtHomeMailValidator = validatorError(isAlphaNumericHyphen, ErrorCodes.INVALID_SAH_ALPHANUMERIC_CHARACTER);
export const organizationNameValidator = validatorError(orgName, ErrorCodes.INVALID_ORGANIZATION_NAME);
export const houseNumberValidator = validatorError(isHouseNumber, ErrorCodes.INVALID_HOUSE_NUMBER);
export const unitValidator = validatorError(isUnit, ErrorCodes.INVALID_UNIT_NUMBER);
export const numberValidator = validatorError(isNumber, ErrorCodes.INVALID_NUMBER);
export const decimalValidator = validatorError(noDecimal, ErrorCodes.INVALID_PERCENTAGE_DECIMAL);
export const streetAddressValidator = validatorError(isStreetAddress, ErrorCodes.INVALID_STREET_ADDRESS);
export const commentsValidator = validatorError(isComments, ErrorCodes.INVALID_COMMENTS);
export const zipCodeValidator = validatorError(isZipCode, ErrorCodes.INVALID_ZIP_CODE);
export const cityValidator = validatorError(isCity, ErrorCodes.INVALID_CITY);
export const languageValidator = validatorError(isLanguage, ErrorCodes.INVALID_LANGUAGE);
export const medicareNumberValidator = validatorError(isMedicareNumber, ErrorCodes.INVALID_MEDICARE_NUMBER);
export const topLevelValidator = validatorError(isValidTopLevel, ErrorCodes.INVALID_TOP_LEVEL);
export const dateNotAfterMonthValidator = validatorError(beforeSecondMonth, ErrorCodes.DATE_NOT_AFTER_MONTH);
export const dueDateValidator = validatorError(withInNineMonths, ErrorCodes.DATE_DUE);
export const maxDueDateValidator = validatorError(after10Months, ErrorCodes.MAX_DATE_DUE);
export const returnDateValidator = validatorError(invalidTodayDate, ErrorCodes.INVALID_MAX_DATE);
export const dateNotBefore30DaysValidator = validatorError(before30Days, ErrorCodes.DATE_NOT_BEFORE_30_DAYS);
export const dateNotBefore90DaysValidator = validatorError(before90Days, ErrorCodes.DATE_NOT_BEFORE_90_DAYS);
export const dateNotBefore120YearsValidator = validatorError(before120Years, ErrorCodes.DATE_NOT_BEFORE_120_YEARS);
export const babyDateNotGreaterThan1Year = validatorError(before1Year, ErrorCodes.BABY_DATE_NOT_GREATER_THAN_1_YEAR);
export const dateNotBefore60DaysValidator = validatorError(before60Days, ErrorCodes.DATE_NOT_BEFORE_60_DAYS);
export const dateNotBefore5MonthsValidator = validatorError(before5Months, ErrorCodes.FIVE_MONTHS_MAX);
export const dateNotBefore12MonthsValidator = validatorError(before5Months, ErrorCodes.TWELVE_MONTHS_MAX);
export const invalidFutureDate = validatorError(invalidFutureDays, ErrorCodes.INVALID_FUTURE_DATE);
export const unmatchedFieldsValidator = compareControls(unequal, ErrorCodes.UNMATCHED_FIELDS, useValues);
export const dateStartAfterEndValidator = compareControls(isStartAfterEnd, ErrorCodes.DATE_START_AFTER_END, useValues);
export const invalidFieldValidator = validatorError(isValidName, ErrorCodes.INVALID_FIELD);
export const validDateValidator = validatorError(isValidDate, ErrorCodes.INVALID_DATE);
export const vehicleYearValidator = validatorError(vehicleYearBetween, ErrorCodes.INVALID_VEHICLE_YEAR);
export const lessThanValidator = errorCode => compareControls(lt, errorCode, useNumericValues);
export const documentDescriptionValidator = validatorError(isDocDecription, ErrorCodes.INVALID_DOCUMENT_DESCRIPTION);
export const phoneNumberValidator = validatorError(isValidPhNumber, ErrorCodes.PHONE_NUMBER);
export const i94Validator =  validatorError(isI94, ErrorCodes.INVALID_I94);
export const alphanumericValidator = validatorError(isAlphaNumeric, ErrorCodes.INVALID_ALPHANUMERIC_CHARACTER);
export const alienValidator = validatorError(isAlien, ErrorCodes.INVALID_ALIEN_NUMBER);
export const cardNumberValidator = validatorError(isCard, ErrorCodes.INVALID_CARD_NUMBER);
export const sevisValidator = validatorError(isSevis, ErrorCodes.INVALID_SEVIS_ID);
export const certificateValidator = validatorError(iscitizenshipNumber, ErrorCodes.INVALID_CITIZENSHIP_CERTIFICATE);
export const naturalizationValidator = validatorError(iscitizenshipNumber, ErrorCodes.INVALID_NATURALIZATION_NUMBER);
export const currencyValidator = validatorError(currency, ErrorCodes.INVALID_CURRENCY);
export const hoursValidator = validatorError(currency, ErrorCodes.INVALID_HOURS);
export const ssnValidator = validatorError(isValidSSN, ErrorCodes.INVALID_SSN);
export const personIdValidator = validatorError(isPersonId, ErrorCodes.INVALID_PERSON_ID);
export const passportValidator = validatorError(isPassport, ErrorCodes.INVALID_PASSPORT_NUMBER);
export const splCharValidator = validatorError(isSplCharacter, ErrorCodes.INVALID_USER_NAME);
export const userNameEmail = validatorError(isUserNameEmail, ErrorCodes.INVALID_USER_NAME_EMAIL);
export const userNameLength = validatorError(isWithinLength, ErrorCodes.INVALID_LENGTH);
export const noSpaceInUsername = validatorError(noSpace, ErrorCodes.NO_SPACE);
export const answerValidator = validatorError(isWithinAnswerLength, ErrorCodes.INVALID_ANSWER);
export const submittedByValidator = validatorError(isSubmittedBy, ErrorCodes.INVALID_SUBMITTEDBY);
export const signatureNameValidator = validatorError(isValidSignatureName, ErrorCodes.INVALID_TAX_NAME);

const validatePeriodHours = control =>
  cond([
    [hoursbyPeriod('B', 173), () => setValidationError(control, ErrorCodes.PERIOD_BIWEEKLY)],
    [hoursbyPeriod('M', 346), () => setValidationError(control, ErrorCodes.PERIOD_MONTHLY)],
    [hoursbyPeriod('S', 160), () => setValidationError(control, ErrorCodes.PERIOD_SEMIMONTHLY)],
    [hoursbyPeriod('W', 80), () => setValidationError(control, ErrorCodes.PERIOD_WEEKLY)],
    [T, stubNull],
  ]);

export const wagesPeriodHourValidator = curry((wagesPeriod, hoursAverage, control) => {
  const periodControl = get(wagesPeriod, control);
  const hoursControl = get(hoursAverage, control);

  return validatePeriodHours(hoursControl)({
    period: valueCode(periodControl),
    hours: value(hoursControl),
  });
});

export const totalValidator = (control : FormGroup) => {
  const jointOwnershipInformation = control.controls['jointOwnershipInformation'] as FormArray;
  const total = jointOwnershipInformation.controls.reduce((total, current: FormGroup) => {
    const value = (+current.controls['jointOwnerPercentage'].value);
    return value + total;
  }, 0);
  return total === 100 || jointOwnershipInformation.disabled ? null : {error : 'Please make sure that the percentages of ownership add up to 100%'};
}

export const dropdownValidator =  (control : FormGroup) => {
  const jointOwnershipInformation = control.controls['jointOwnershipInformation'] as FormArray;
  const values = jointOwnershipInformation.value.filter(item => item.jointOwnerIndividual?.code !== 'EMPTY').map(item => item.jointOwnerIndividual);
  const valueArr = values.map(val =>  {if (val) return val.code });
  const isDuplicate = valueArr.some((item, idx) => valueArr.indexOf(item) != idx );
  return  !isDuplicate || jointOwnershipInformation.disabled ? null : {error : 'dropdown not correct'};
}
