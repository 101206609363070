import { Component, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormBuilder } from '@angular/forms';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import Vs from '@app/services/validators/validators.service';
import { ApiService } from "@app/gateway/services/api.service";
import { ProcessService } from "@app/services/process.service";
import { ModalService } from "@app/services/modal/modal.service";
import { Subscription } from 'rxjs';
import { getDataValue } from "@app/helpers/object.helpers";
import { equals, isNil, isEmpty } from 'ramda';
import { isMilitary } from '@app/helpers/models.helpers';
import { disableResetControls, enableResetControls, resetDisableControls, resetEnableControls } from '@app/helpers/reset-able.helpers';
import { distinctUntilChanged } from "rxjs/operators";
import { HttpHeaders } from '@angular/common/http';

@Component({
  templateUrl: './appeal-representative-details.component.html',
  styleUrls: ['./appeal-representative-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AppealRepresentativeDetailsComponent extends SectionFormDirective implements AfterContentInit {
  translatedLang;
  defaultLanguage;
  data;
  eligAuthResprentativeSw;
  authRepresntativeObj;
  individualTable = [];
  relationshipSelected;
  addressSubrep: Subscription;
  isMilitary;
  appealRelationshipOptions = [];
  records: any;
  status: any;
  showRelationValidation = false;
  appealAddress;
  hideTopQuestions = false;
  authEligibleExists = false;
  nameFieldsMandatory = true;
  organizationMadatory = true;

  constructor(public builder: FormBuilder, public translateService: TranslateService, public processService: ProcessService, public apiService: ApiService, private modalService: ModalService) {
    super(builder);
    this.addressSubrep = this.processService.processAddressValidate.subscribe((action) => this.addressValidate(action));
  };

  addressValidate(action) {
    let postObject;
    const appNum = this.applicationNumber;
    const formData = this.formGroup.value.data;
    let appealAddress = getDataValue('representativeAddress.address', formData);
    ['addrAaAeAp', 'addrApoFpo'].forEach(e => delete appealAddress[e]);
    const isSaveExit = !!equals(action, 'fromSaveExit');
    postObject = {
      addressList: [
        { addressType: 'PHYSICAL', originalAddress: appealAddress },
      ]
    }
    if (postObject && isEmpty(postObject.addressList)) {
      this.processService.addressValidated = true;
    } else {
      const contentHeader = new HttpHeaders({'pageId':'ARPDT'})
      this.apiService.post('applications/' + appNum + '/sections/ARPDT/address', postObject, {headers: contentHeader}, true).subscribe((res) => {
        if (!isNil(res)) {
          this.modalService.openAddressModal(res, isSaveExit).subscribe(result => {
            if (result !== true) {
              if (result) {
                const phyIndex = result.findIndex(i => i.addressType === 'PHYSICAL');
                if (phyIndex === 0) {
                  const addressLine1 = this.getControl('data.representativeAddress.address.addressLine1', this.formGroup);
                  const addressLine2 = this.getControl('data.representativeAddress.address.addressLine2', this.formGroup);
                  const city = this.getControl('data.representativeAddress.address.city', this.formGroup);
                  const state = this.getControl('data.representativeAddress.address.state', this.formGroup);
                  const zip = this.getControl('data.representativeAddress.address.zip', this.formGroup);

                  if (result[0].selectedAddress === 'Suggested') {
                    addressLine1.setValue(result[0].suggestedAddress.addressLine1);
                    addressLine2.setValue(result[0].suggestedAddress.addressLine2);
                    city.setValue(result[0].suggestedAddress.city);
                    result[0].suggestedAddress.state = {'code': result[0].originalAddress.state.code, 'description': result[0].originalAddress.state.value, 'spDescription': result[0].originalAddress.state.value, 'value': result[0].originalAddress.state.value}
                    state.setValue(result[0].originalAddress.state);
                    zip.setValue(result[0].suggestedAddress.zip);
                  } else if (result[0].selectedAddress === 'Original') {
                    addressLine1.setValue(result[0].originalAddress.addressLine1);
                    addressLine2.setValue(result[0].originalAddress.addressLine2);
                    city.setValue(result[0].originalAddress.city);
                    result[0].originalAddress.state = {'code': result[0].originalAddress.state.code, 'description': result[0].originalAddress.state.value, 'spDescription': result[0].originalAddress.state.value, 'value': result[0].originalAddress.state.value}
                    state.setValue(result[0].originalAddress.state);
                    zip.setValue(result[0].originalAddress.zip);
                  }
                };
                this.processService.addressValidated = true;
              }
            }
          });
        } else {
          this.processService.addressValidated = true;
        }
      });
    }
  }

  onLoad() {
    this.eligAuthResprentativeSw = this.records[0]['data']['eligAuthResprentativeSw']
    this.authRepresntativeObj = this.records[0]['data']['eligibleRepresentative']
    let relation = this.getValue('data.appeallantRelationship');
    if (this.authRepresntativeObj?.['eligRepresentativeName']) {
        const checkBoxItem = this.authRepresntativeObj['eligRepresentativeName'];
        const checkBoxValue = this.authRepresntativeObj['eligAuthSelected'];
        let checkBoxItemRelation;
        if (this.defaultLanguage === 'en') {
          checkBoxItemRelation = this.authRepresntativeObj['eligRepresentRelation']['value'];
        } else {
          checkBoxItemRelation = this.authRepresntativeObj['eligRepresentRelation']['spValue'];
        }
        if(this.individualTable.length === 0) {
          this.individualTable.push({ name: checkBoxItem, eligAuthSelected: checkBoxValue, relation: checkBoxItemRelation})
        }
      if (this.authRepresntativeObj['eligAuthSelected'] !== true) {
        let authElig
        for (let i = 0; this.records.length > i; i++) {
          const appealAuthRepData = this.records[i]['data']['eligibleRepresentative'];
          authElig = appealAuthRepData?.eligAuthSelected === true;
        }
        if (authElig?.length) {
          this.authRepresntativeObj['eligAuthSelected'] = true;
        }
      }
    }

    if (this.records.length >= 0) {
      this.getControl('data.eligibleRepresentative').patchValue(this.records[0]['data']['eligibleRepresentative']);
      this.getControl('data.eligAuthResprentativeSw').patchValue(this.records[0]['data']['eligAuthResprentativeSw'])
    }

    if (relation?.code) {
      this.relationshipSelected = true;
    }

    let addReprentativeValue = this.getValue('data.addReprentative')
    const removed = JSON.parse(sessionStorage.getItem('removedAppealRecord'));
    if (this.records && this.records.length >= 1 && this.records[0]['data']['addReprentative']) {
      this.getControl('data.oldAppeallantRelationship').patchValue(this.getValue('data.appeallantRelationship'));
      if(addReprentativeValue) {
        this.hideTopQuestions = true;
      } else{
      if(!this.records[0]['data']?.eligibleRepresentative || this.records[0]['data']?.eligibleRepresentative?.eligAuthSelected === true) {
        this.hideTopQuestions = true;
        this.getControl('data.addReprentative').patchValue({code: "Y", value: "Yes", spValue: "Si"})
      } else {
        this.hideTopQuestions = false;
      }
    }
      
      addReprentativeValue = this.getValue('data.addReprentative')
    } else if (addReprentativeValue === "" && this.records && this.records.length === 1 && !this.records[0]['data']['addReprentative'] && removed) {
      this.getControl('data.addReprentative').patchValue({code: "Y", value: "Yes", spValue: "Si"})
      addReprentativeValue = this.getValue('data.addReprentative')
      sessionStorage.removeItem('removedAppealRecord')
    } else if (addReprentativeValue === "" && this.status === "COMPLETE" && !this.records[0]['data']['addReprentative'] && !removed) {
      this.getControl('data.addReprentative').patchValue({code: "N", value: "No", spValue: "No"})
      addReprentativeValue = this.getValue('data.addReprentative');
      this.hideTopQuestions = false;
    }
    if (addReprentativeValue?.code) {
      this.addReprentative()
    } else {
      this.authEligibleExists = false;
    }

    if(this.records.length === 5 && this.eligAuthResprentativeSw === 'Y' && this.authRepresntativeObj['eligAuthSelected'] !== true) {
      this.getControl('data.addReprentative').patchValue({code: "N", value: "No", spValue: "No"});
      this.getControl('data.addReprentative').updateValueAndValidity();
      this.getControl('data.addReprentative').disable();
      this.getControl('data.representativeAddress.address').disable();
    }

    const addrApoFpoControl = this.getControl('data.representativeAddress.address.addrApoFpo');
    if (addrApoFpoControl?.value) {
      const tableVar = this.tables.MILITARYPOCODES.filter((item) => item.code === addrApoFpoControl.value.code);
      tableVar[0].value = this.translatedLang === 'en' ? addrApoFpoControl.value.value : addrApoFpoControl.value.spValue;
      addrApoFpoControl.patchValue(tableVar[0]);
    };

    const addrAaAeApControl = this.getControl('data.representativeAddress.address.addrAaAeAp');
    if (addrAaAeApControl?.value) {
      const tableVar = this.tables.STATEMILITARY.filter((item) => item.code === addrAaAeApControl.value.code);
      tableVar[0].value = this.translatedLang === 'en' ? addrAaAeApControl.value.value : addrAaAeApControl.value.spValue;
      addrAaAeApControl.patchValue(tableVar[0]);
    };

    const countyValue = this.getValue('data.representativeAddress.address.county');
    if(countyValue?.value) {
      const countyControl = this.getControl('data.representativeAddress.address.county');
      const tableCounty = this.tables.COUNTY.filter((item) => item.code === countyValue?.code);
      tableCounty[0].value = countyValue?.value; this.translatedLang === 'en' ? countyValue?.value : countyValue?.spValue;
      countyControl.patchValue(tableCounty[0]);
    };

    this.appealAddress = this.getControl('data.representativeAddress.address');
}

  ngAfterContentInit() {
    this.data = this.getControl('data');
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    this.onLoad()
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
      this.onLoad()
    });
    this.appealAddress.valueChanges.pipe().subscribe(() => {
      this.processService.addressValidated = false;
    });
    let cell = this.getControl('data.contactDetails.cell');
    let work = this.getControl('data.contactDetails.work');
    this.getControl('data.contactDetails.cell').patchValue(cell.value.substring(1, 4) + cell.value.substring(6, 9) + cell.value.substring(10, 15));
    this.getControl('data.contactDetails.work').patchValue(work.value.substring(1, 4) + work.value.substring(6, 9) + work.value.substring(10, 15));

    this.getControl('data.name.firstName').valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      this.controlNameValidations();
    })

    this.getControl('data.name.lastName').valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      this.controlNameValidations();
    })

    this.getControl('data.organizationName').valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      this.controlNameValidations();
    })
  }

  onInput(event : Event){
    const input = event.target as HTMLTextAreaElement;
    const cursorPosition = input.selectionStart;
    const value = input.value;
    const filteredValue = this.removeEmojis(value)
    // Update the textarea value without emojis
     input.value = filteredValue;
     // Restore the cursor position
     input.setSelectionRange(cursorPosition, cursorPosition);
     this.getControl(`data.${input.name}`, this.formGroup).patchValue(filteredValue);
 
  }
  
  removeEmojis(value: string) {
    const emojiRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]|[\r]|[^a-zA-Z0-9\n\\\\s~`! @#$%^&*()_|'"\-+={}\[\]:;”’,.?/])/g;
    return value.replace(emojiRegex, '');
  }


  controlNameValidations() {
    this.getControl('data.contactDetails.cell').setValidators([Vs.required, Vs.phoneNumber]);
    this.getControl('data.contactDetails.cell').updateValueAndValidity();  
    if (this.getControl('data.name.firstName').value && this.getControl('data.name.firstName').status === 'VALID' && this.getControl('data.name.lastName').value && this.getControl('data.name.lastName').status === 'VALID') {
      this.nameFieldsMandatory = true;
      this.organizationMadatory = false;
      this.getControl('data.organizationName').setValidators([Vs.maxLength(45)]);
      this.getControl('data.organizationName').updateValueAndValidity();
    } else if(this.getControl('data.organizationName').value && this.getControl('data.organizationName').status === 'VALID') {
        this.nameFieldsMandatory = false;
        this.organizationMadatory = true;
        this.getControl('data.name.firstName').setValidators([Vs.maxLength(45), Vs.firsName]);
        this.getControl('data.name.firstName').updateValueAndValidity();
        this.getControl('data.name.lastName').setValidators([Vs.maxLength(45), Vs.minLength(2), Vs.lastName]);
        this.getControl('data.name.lastName').updateValueAndValidity();
        this.getControl('data.name.middleInitial').setValidators([Vs.individualName]);
        this.getControl('data.name.middleInitial').updateValueAndValidity();
    } else {
      this.nameFieldsMandatory = true;
      this.organizationMadatory = true;
      this.getControl('data.organizationName').setValidators([Vs.maxLength(45), Vs.required]);
      this.getControl('data.organizationName').updateValueAndValidity();
      this.getControl('data.name.firstName').setValidators([Vs.maxLength(45), Vs.firsName, Vs.required]);
      this.getControl('data.name.firstName').updateValueAndValidity();
      this.getControl('data.name.lastName').setValidators([Vs.maxLength(45), Vs.minLength(2), Vs.lastName, Vs.required]);
      this.getControl('data.name.lastName').updateValueAndValidity();
      this.getControl('data.name.middleInitial').setValidators([Vs.individualName]);
      this.getControl('data.name.middleInitial').updateValueAndValidity();
    }
  }

  onRelationChange() {
    const relationValue = this.getValue('data.appeallantRelationship')
    if (relationValue) {
      this.relationshipSelected = true;
      this.getControl('data.representativeAddress.addressFormat').setValidators(Vs.required);
      this.getControl('data.representativeAddress.addressFormat').updateValueAndValidity();
      this.getControl('data.contactDetails.cell').setValidators([Vs.required, Vs.phoneNumber]);
      this.getControl('data.contactDetails.cell').updateValueAndValidity();
      if (this.records && this.records.length) {
        const providerCodes = [];
        this.records.forEach( (element) => {
          providerCodes.push(element.data?.appeallantRelationship?.code)
        })
        this.showRelationValidation = providerCodes.includes(relationValue.code);
      }
      if (this.showRelationValidation) {
       this.getControl('data.duplicateRelationship').setValidators(Vs.required);
       this.getControl('data.duplicateRelationship').updateValueAndValidity();
      } else {
      this.getControl('data.duplicateRelationship').clearValidators();
      this.getControl('data.duplicateRelationship').updateValueAndValidity();
      }
    } else {
      this.getControl('data.representativeAddress.addressFormat').clearValidators();
      this.getControl('data.representativeAddress.addressFormat').updateValueAndValidity();
      this.getControl('data.contactDetails.cell').clearValidators();
      this.getControl('data.contactDetails.cell').updateValueAndValidity()
      this.relationshipSelected = false;
    }
  }


  addressFormat() {
    const addressFormatCode = this.getValue('data.representativeAddress.addressFormat');
    const addressControl = this.getControl('data.representativeAddress.address')
    if (addressFormatCode.code !== 'MI') {
      this.getControl('data.representativeAddress.address.addrAaAeAp').clearValidators();
      this.getControl('data.representativeAddress.address.addrAaAeAp').updateValueAndValidity();
      this.getControl('data.representativeAddress.address.addrApoFpo').clearValidators();
      this.getControl('data.representativeAddress.address.addrApoFpo').updateValueAndValidity();
    } else {
      this.getControl('data.representativeAddress.address').reset();
    }
    const city = this.getControl('data.representativeAddress.address.city');
    const state = this.getControl('data.representativeAddress.address.state');
    const county = this.getControl('data.representativeAddress.address.county');
    const zip = this.getControl('data.representativeAddress.address.zip');
    const addrApoFpo = this.getControl('data.representativeAddress.address.addrApoFpo');
    const addrAaAeAp = this.getControl('data.representativeAddress.address.addrAaAeAp');
    const whenMilitary = [addrApoFpo, addrAaAeAp];
    const whenNotMilitary = [city, state, county];
    this.isMilitary = isMilitary(addressFormatCode);
    if (addressFormatCode) {
      if (addressControl.status === 'DISABLED') {
        return null;
      } else {
        if (this.isMilitary) {
          resetEnableControls(whenMilitary);
          resetDisableControls(whenNotMilitary);
          zip.reset()
        } else {
          enableResetControls(whenNotMilitary);
          disableResetControls(whenMilitary);
          zip.reset();
          county?.markAsPristine();
          county?.markAsUntouched();
        }
      }
    }
  }

  selectCheckBox(event) {
    this.authRepresntativeObj['eligAuthSelected'] = event?.target?.checked;
    if(this.data.value.eligibleRepresentative){
      this.data.value.eligibleRepresentative['eligAuthSelected'] = event?.target?.checked;
    } else {
      this.getControl('data.eligibleRepresentative').patchValue(this.authRepresntativeObj);
      this.data.value.eligibleRepresentative['eligAuthSelected'] = event?.target?.checked;
    }
    
    this.authEligibleExists = event?.target?.checked;
  }

  tableCodeValue(tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang, false)
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  addReprentative() {
    const addReprentative = this.getValue('data.addReprentative');
    if (addReprentative.code === 'Y') {
      this.getControl('data.appeallantRelationship').setValidators([Vs.required]);
      this.getControl('data.appeallantRelationship').updateValueAndValidity();
    } else {
      this.relationshipSelected = false;
      this.getControl('data.appeallantRelationship').reset();
      this.getControl('data.appeallantRelationship').clearValidators();
      this.getControl('data.appeallantRelationship').updateValueAndValidity();
      this.getControl('data.name').reset();
      this.getControl('data.name.firstName').setValidators([Vs.maxLength(45), Vs.firsName]);
      this.getControl('data.name.firstName').updateValueAndValidity();
      this.getControl('data.name.lastName').setValidators([Vs.maxLength(45), Vs.minLength(2), Vs.lastName]);
      this.getControl('data.name.lastName').updateValueAndValidity();
      this.getControl('data.name.middleInitial').setValidators([Vs.individualName]);
      this.getControl('data.name.middleInitial').updateValueAndValidity();
      this.getControl('data.organizationName').reset();
      this.getControl('data.organizationName').setValidators([Vs.maxLength(45)]);
      this.getControl('data.organizationName').updateValueAndValidity();
      this.getControl('data.representativeAddress.addressFormat').reset();
      this.getControl('data.representativeAddress.addressFormat').clearValidators();
      this.getControl('data.representativeAddress.addressFormat').updateValueAndValidity();
      this.getControl('data.contactDetails').reset();
      this.getControl('data.contactDetails.cell').clearValidators();
      this.getControl('data.contactDetails.cell').updateValueAndValidity();
      this.getControl('data.representativeAddress.address').reset();
      this.getControl('data.representativeAddress.address.addrAaAeAp').clearValidators();
      this.getControl('data.representativeAddress.address.addrAaAeAp').updateValueAndValidity();
      this.getControl('data.representativeAddress.address.addrApoFpo').clearValidators();
      this.getControl('data.representativeAddress.address.addrApoFpo').updateValueAndValidity();
      this.getControl('data.representativeAddress.address.addressLine1').clearValidators();
      this.getControl('data.representativeAddress.address.addressLine1').updateValueAndValidity();
      this.getControl('data.representativeAddress.address.city').clearValidators();
      this.getControl('data.representativeAddress.address.city').updateValueAndValidity();
      this.getControl('data.representativeAddress.address.county').clearValidators();
      this.getControl('data.representativeAddress.address.county').updateValueAndValidity();
      this.getControl('data.representativeAddress.address.state').clearValidators();
      this.getControl('data.representativeAddress.address.state').updateValueAndValidity();
      this.getControl('data.representativeAddress.address.zip').clearValidators();
      this.getControl('data.representativeAddress.address.zip').updateValueAndValidity();
    }
  }

  resetAll() {
    this.getControl('data.appeallantRelationship').reset();
    this.getControl('data.name').reset();
    this.getControl('data.gender').reset();
    this.getControl('data.organizationName').reset();
    this.getControl('data.representativeAddress.addressFormat').reset();
    this.getControl('data.representativeAddress.address').reset();
    this.getControl('data.contactDetails').reset();
  }

  ngOnDestroy() {
    this.addressSubrep.unsubscribe();
  }

}