import { evolve, compose, pathOr } from 'ramda';
import { EmploymentComponent } from './employment.component';
import employmentLabels from './employement-labels';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration, nonMandatoryAddress } from '@app/helpers/models.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { hasYN, nilOrYN, notYes } from '@app/helpers/tayn.helpers';
import { isNull } from 'util';
import { controlWith } from '@app/helpers/form.helpers';

const formValidation = (builder, recordData) => {
  const employment = lookIn(recordData);
  const address = employment(['employer', 'address']);

  // If creating a record is null
  const addAddress = hasYN('zip', address);
  const paymentOption = nilOrYN(true);

  const helpers = builder.group({
    addAddress,
    paymentOption: [paymentOption, Vs.required],
  });

  const data = builder.group(
    {
      employer: builder.group({
        address: builder.group(nonMandatoryAddress(employment(['employer', 'address']))),
        name: [employment(['employer', 'name']), [Vs.required, Vs.invalidEmployerName, Vs.maxLength(100)]],
        phoneNumber: [employment(['employer', 'phoneNumber']), [Vs.phoneNumber]],
      }),
      startDate: [toDate(employment(['startDate']))],
      loseJobSw: [employment(['loseJobSw'])],
      loseJobEndDate: [controlWith(toDate(employment(['loseJobEndDate'])), notYes(employment(['loseJobSw']))), [Vs.required]],
      wage: builder.group(
        {
          hoursWorkedPeriod: [employment(['wage', 'hoursWorkedPeriod']), [Vs.required, Vs.hours]],
          paymentAmtPerHr: [employment(['wage', 'paymentAmtPerHr']), [Vs.required, Vs.currency]],
          paymentPeriod: [employment(['wage', 'paymentPeriod']), [Vs.required]],
          paymentAmountPerPayCheck: [employment(['wage', 'paymentAmountPerPayCheck']), [Vs.required, Vs.currency]],
        },
      ),
      jobTitle: [employment(['jobTitle']), Vs.maxLength(15)],
      employmentDateValidation: null,
    },
    //  { updateOn: 'change', validator: Vs.startAfterEnd('startDate', 'employmentLost.date') }
  );
  return { helpers, data };
};

const postObject = evolve({
  startDate: toISO,
  loseJobEndDate: toISO,
  employmentLost: {
    date: toISO,
    finalPayheckDate: toISO,
    hoursCurrentMonth: Number,
  },
  wage: {
    hoursWorkedPeriod: Number,
  },
});

const nullPaymentPeriod = compose(
  isNull,
  pathOr(null, ['paymentPeriod'])
);

const hoursWorkedPeriod = pathOr('', ['hoursWorkedPeriod']);
const paymentPeriod = pathOr('', ['paymentPeriod', 'value']);
const frequency = wage =>
  nullPaymentPeriod(wage) ? sessionStorage.getItem('currentLang') === 'en' ? 'Hourly </br>' + hoursWorkedPeriod(wage) + ' hr/week' : 'Cada hora </br>' + hoursWorkedPeriod(wage) + ' hr/week' : paymentPeriod(wage);

const paymentAmtPerHr = pathOr(0, ['paymentAmtPerHr']);
const paymentAmountPerPayCheck = pathOr(0, ['paymentAmountPerPayCheck']);

const amount = (wage, { currency }) =>
  currency.transform(nullPaymentPeriod(wage) ? paymentAmtPerHr(wage) : paymentAmountPerPayCheck(wage));


export default sectionConfiguration(
  EmploymentComponent,
  formValidation,
  ['YESNO', 'TSTS', 'TDSA', 'TACY', 'TSTA', 'TATR', 'STATE', 'COUNTY', 'INCEXPPAYFREQUENCY', 'MPPAYFREQ'],
  [
    { name: 'Name', pipe: 'individual' },
    { name: 'Employer', prop: 'data.employer.name' },
    { name: 'Start Date', prop: 'data.startDate', pipe: 'date', args: 'shortDate' },
    { name: 'How Often', prop: 'data.wage', pipe: 'domain', args: frequency },
    { name: 'Amount', prop: 'data.wage', pipe: 'domain', args: amount },
  ],
  { ...{}, ...employmentLabels },
  {
    postObject
  }
);
