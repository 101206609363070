import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isEmpty } from 'ramda';
import { CreateAccountService } from '@app/gateway/services/createAccount.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nh-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit, OnDestroy {
  appType = '';
  successMessage: any;
  subscription: Subscription;
  manageAccountSubscription: Subscription
  userDetails: any;
  userName: any = JSON.parse(sessionStorage.getItem('currentUser'))['userId'];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private createAccountService: CreateAccountService,
    private titleService: Title) { 
      this.titleService.setTitle('Manage my Account');
    }

  ngOnInit(): void {
    sessionStorage.removeItem('submitSuccess');
    this.activatedRoute.queryParams.subscribe((res) => {
      if (!isEmpty(res)) {
        this.successMessage = res
      }
    })
    this.subscription = this.createAccountService.getUserObj().subscribe((res) => {
      if (res && !isEmpty(res)) {
        this.userDetails = res
      }
    });
    this.manageAccountSubscription = this.createAccountService.getmanageAccountAction().subscribe((res) => {
      if (res === 'next') {
        this.navigateToPage();
      } else if (res === 'cancel') {
        this.resetAction();
        this.createAccountService.manageAccountFormUpdateState = false;
      }
    });
  }

  selectedAction(value) {
    this.appType = value;
    this.createAccountService.manageAccountFormUpdateState = true;
  }

  resetAction() {
    this.appType = '';
  }

  navigateToPage(): void {
    switch (this.appType) {
      case 'updatePassword':
        this.router.navigateByUrl('user-profile/change-password');
        break;
      case 'updateSecurityQuestion':
        this.router.navigateByUrl('user-profile/update-security-questions');
        break;
      case 'updateEmail':
        this.router.navigateByUrl('user-profile/update-email');
        break;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.manageAccountSubscription.unsubscribe();
  }

}
