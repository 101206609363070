import { Component, AfterViewInit, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './summary-appeal.component.html',
  styleUrls: ["./summary-appeal.component.scss"],
})

export class SummaryAppealComponent extends SectionFormDirective implements AfterContentInit, AfterViewInit {


  constructor(builder: FormBuilder,
    private titleService: Title, 
    public translateService: TranslateService
    ) {
    super(builder)
    this.titleService.setTitle('Appeal-Summary');
  }
  defaultLanguage;
  translatedLang;
  APPEAL_SUMMARY_STATIC1;
  APPEAL_SUMMARY_STATIC1en = 'Appellant Information Summary';
  APPEAL_SUMMARY_STATIC1sp = 'Resumen de la Información del Apelante';
  APPEAL_SUMMARY_STATIC2;
  APPEAL_SUMMARY_STATIC2en = 'Do you need to make a change before you submit? <b>Click the green check mark at the top of your screen for the section you want to change.</b>';
  APPEAL_SUMMARY_STATIC2sp = '¿Necesita hacer un cambio antes de enviar? En la parte superior de la pantalla, haga clic en la marca de verificación verde de la sección que quiere cambiar.';
  APPEAL_SUMMARY_STATIC3;
  APPEAL_SUMMARY_STATIC3en = 'Appeal Request Information';
  APPEAL_SUMMARY_STATIC3sp = 'Información de la Solicitud de Apelación';
  APPEAL_SUMMARY_STATIC4;
  APPEAL_SUMMARY_STATIC4en = 'Below is the information you told us about your appeal:';
  APPEAL_SUMMARY_STATIC4sp = 'A continuación se muestra la información que nos proporcionó sobre su apelación:';
  APPEAL_SUMMARY_STATIC5;
  APPEAL_SUMMARY_STATIC5en = 'Appellant Demographics';
  APPEAL_SUMMARY_STATIC5sp = 'Datos Demográficos del Apelante';
  APPEAL_SUMMARY_STATIC6;
  APPEAL_SUMMARY_STATIC6en = 'Below is the information you told us about where you live and how we can contact you.';
  APPEAL_SUMMARY_STATIC6sp = 'Abajo está la información que nos proporcionó sobre el motivo por el que presenta una apelación.';
  APPEAL_SUMMARY_STATIC7;
  APPEAL_SUMMARY_STATIC7en = 'Appeal Details';
  APPEAL_SUMMARY_STATIC7sp = 'Detalles de la apelación';
  APPEAL_SUMMARY_STATIC8;
  APPEAL_SUMMARY_STATIC8en = 'Below is the information you told us about what your appeal is about.';
  APPEAL_SUMMARY_STATIC8sp = 'Abajo está la información que nos proporcionó sobre el motivo de su apelación.';
  APPEAL_SUMMARY_STATIC9;
  APPEAL_SUMMARY_STATIC9en = 'Appeal Representative';
  APPEAL_SUMMARY_STATIC9sp = 'Representante para la Apelación';
  APPEAL_SUMMARY_STATIC10;
  APPEAL_SUMMARY_STATIC10en = 'Below is the information you told us about who may represent you.';
  APPEAL_SUMMARY_STATIC10sp = 'Abajo está la información que nos proporcionó sobre quién puede representarlo.';
 data;
 appealRequestInformationArray = [];
 appealDetailsArray = [];
 appealDemographicsArray = [];
 appealRepresentativeArray = [];
 editKey;
 array;
 array2;
 coverageKey;

  ngAfterContentInit() {
    this.data = this.getValue('data');
    sessionStorage.removeItem('removedAppealRecord')
    this.appealRequestInformationArray = this.data?.aplReqInfo?.aplRequest?.request;
    this.appealDetailsArray = this.data?.aplDetails?.aplRequest?.request;
    this.appealDemographicsArray = this.data?.aplDemographics?.aplRequest?.request;
    this.editKey = this.appealDetailsArray.findIndex(item => item.key.includes('You applied for benefits on'));
    const string1 = this.appealDetailsArray[this.editKey].key;
    this.array = string1.split('on');
    const string2 = this.array[1];
    this.array2 = string2.split(',')
    this.appealDetailsArray = this.appealDetailsArray.map(item => {
      if (item.key.includes('Coverage Type for ')) {
        item.name = item.key.replace('Coverage Type for', '');
        item.coverageKey = 'Coverage Type for';
      }
      return item;
    });
    this.appealRepresentativeArray = this.data?.aplRepresentativeDetails?.aplRequest;
    if(this.appealRepresentativeArray.length > 0){
      this.appealRepresentativeArray = this.appealRepresentativeArray.map(item => {
        item.request.forEach(element => {
        if (['Cell Phone', 'Work Phone', 'Fax'].includes(element.key)) {
          element.value = `(${element.value.substr(0, 3)}) ${element.value.substr(3, 3)}-${element.value.substr(6)}`;
        }
        })
        return item;
      })
    }
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.getLables(this.translatedLang);
    this.getDemographicMilitaryAddress(this.translatedLang);
    this.getRepresentativeMilitaryAddress(this.translatedLang)
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.getLables(this.translatedLang);
      this.getDemographicMilitaryAddress(this.translatedLang);
      this.getRepresentativeMilitaryAddress(this.translatedLang)
    })
   }

   getDemographicMilitaryAddress(lang) {
    this.appealDemographicsArray = this.appealDemographicsArray.map(item => {
      if (item.key === 'Appellant Address' && lang !== 'en') {
        if(item.value.includes('Air/Army Post Office')) {
          item.value = item.value.replace('Air/Army Post Office', 'Oficina de Correos de la Fuerza Aérea/Ejército')
        } if(item.value.includes('Fleet Post Office')) {
          item.value = item.value.replace('Fleet Post Office', 'Oficina de correos de la Flota')
        }
        if(item.value.includes('Armed Forces America')){
          item.value = item.value.replace('Armed Forces America', 'Fuerzas Armadas de Estados Unidos')
        } else if(item.value.includes('Armed Forces Africa, Canada, Europe, Middle East')) {
          item.value = item.value.replace('Armed Forces Africa, Canada, Europe, Middle East', 'Fuerzas Armadas de ÿfrica, Canadá, Europa, Medio Oriente')
        } else if(item.value.includes('Armed Forces Pacific')) {
          item.value = item.value.replace('Armed Forces Pacific', 'Fuerzas Armadas del Pacífico')
        } else if(item.key.includes('Primary Phone Number')) {
          item.value = `(${item.value.substr(0, 3)}) ${item.value.substr(3, 3)}-${item.value.substr(6)}`;
        }
        else if(item.key.includes('Secondary Phone Number')) {
          item.value = `(${item.value.substr(0, 3)}) ${item.value.substr(3, 3)}-${item.value.substr(6)}`;
        }

      } else {
        if(item.value.includes('Oficina de Correos de la Fuerza Aérea/Ejército')) {
          item.value = item.value.replace('Oficina de Correos de la Fuerza Aérea/Ejército', 'Air/Army Post Office')
        } if(item.value.includes('Oficina de correos de la Flota')) {
          item.value = item.value.replace('Oficina de correos de la Flota', 'Fleet Post Office')
        }
        if(item.value.includes('Fuerzas Armadas de Estados Unidos')){
          item.value = item.value.replace('Fuerzas Armadas de Estados Unidos', 'Armed Forces America')
        } else if(item.value.includes('Fuerzas Armadas de ÿfrica, Canadá, Europa, Medio Orientet')) {
          item.value = item.value.replace('Fuerzas Armadas de ÿfrica, Canadá, Europa, Medio Orientet', 'Armed Forces Africa, Canada, Europe, Middle East')
        } else if(item.value.includes('Fuerzas Armadas del Pacífico')) {
          item.value = item.value.replace('Fuerzas Armadas del Pacífico', 'Armed Forces Pacific')
        } else if(item.key.includes('Primary Phone Number')) {
          item.value = `(${item.value.substr(0, 3)}) ${item.value.substr(3, 3)}-${item.value.substr(6)}`;
        }
        else if(item.key.includes('Secondary Phone Number')) {
          item.value = `(${item.value.substr(0, 3)}) ${item.value.substr(3, 3)}-${item.value.substr(6)}`;
        }
      }
      return item;
    })
   }

   getRepresentativeMilitaryAddress(lang) {
    this.appealRepresentativeArray[0]['request'] = this.appealRepresentativeArray[0]['request'].map(item => {
      if (item.key === 'Address' && lang !== 'en') {
        if(item.value.includes('Air/Army Post Office')) {
          item.value = item.value.replace('Air/Army Post Office', 'Oficina de Correos de la Fuerza Aérea/Ejército')
        } if(item.value.includes('Fleet Post Office')) {
          item.value = item.value.replace('Fleet Post Office', 'Oficina de correos de la Flota')
        }
        if(item.value.includes('Armed Forces America')){
          item.value = item.value.replace('Armed Forces America', 'Fuerzas Armadas de Estados Unidos')
        } else if(item.value.includes('Armed Forces Africa, Canada, Europe, Middle East')) {
          item.value = item.value.replace('Armed Forces Africa, Canada, Europe, Middle East', 'Fuerzas Armadas de ÿfrica, Canadá, Europa, Medio Oriente')
        } else if(item.value.includes('Armed Forces Pacific')) {
          item.value = item.value.replace('Armed Forces Pacific', 'Fuerzas Armadas del Pacífico')
        }

      } else {
        if(item.value.includes('Oficina de Correos de la Fuerza Aérea/Ejército')) {
          item.value = item.value.replace('Oficina de Correos de la Fuerza Aérea/Ejército', 'Air/Army Post Office')
        } if(item.value.includes('Oficina de correos de la Flota')) {
          item.value = item.value.replace('Oficina de correos de la Flota', 'Fleet Post Office')
        }
        if(item.value.includes('Fuerzas Armadas de Estados Unidos')){
          item.value = item.value.replace('Fuerzas Armadas de Estados Unidos', 'Armed Forces America')
        } else if(item.value.includes('Fuerzas Armadas de ÿfrica, Canadá, Europa, Medio Orientet')) {
          item.value = item.value.replace('Fuerzas Armadas de ÿfrica, Canadá, Europa, Medio Orientet', 'Armed Forces Africa, Canada, Europe, Middle East')
        } else if(item.value.includes('Fuerzas Armadas del Pacífico')) {
          item.value = item.value.replace('Fuerzas Armadas del Pacífico', 'Armed Forces Pacific')
        }
      }
      return item;
    })
   }

  ngAfterViewInit() {
    const elementsList: NodeListOf<Element> = document.querySelectorAll(".carat");
    elementsList.forEach((i) => {
      document.getElementById(i.id).addEventListener("click", this.logInfo.bind(this));
      i.nextElementSibling?.addEventListener("click", this.logInfo.bind(this));
    });

    const elementList: NodeListOf<Element> = document.querySelectorAll("#accordion");
    var headings = elementList[0]?.querySelectorAll('[id^="caratId"]');            
    for (let i = 0; i < headings.length; i++) {                                    
      (headings[i] as any).onclick = this.logInfo;
    }
  }

  getLables(lang) {
 if(lang === 'en') {
this.APPEAL_SUMMARY_STATIC1 = this.APPEAL_SUMMARY_STATIC1en;
this.APPEAL_SUMMARY_STATIC2 = this.APPEAL_SUMMARY_STATIC2en;
this.APPEAL_SUMMARY_STATIC3 = this.APPEAL_SUMMARY_STATIC3en;
this.APPEAL_SUMMARY_STATIC4 = this.APPEAL_SUMMARY_STATIC4en;
this.APPEAL_SUMMARY_STATIC5 = this.APPEAL_SUMMARY_STATIC5en;
this.APPEAL_SUMMARY_STATIC6 = this.APPEAL_SUMMARY_STATIC6en;
this.APPEAL_SUMMARY_STATIC7 = this.APPEAL_SUMMARY_STATIC7en;
this.APPEAL_SUMMARY_STATIC8 = this.APPEAL_SUMMARY_STATIC8en;
this.APPEAL_SUMMARY_STATIC9 = this.APPEAL_SUMMARY_STATIC9en;
this.APPEAL_SUMMARY_STATIC10 = this.APPEAL_SUMMARY_STATIC10en
 } else {
  this.APPEAL_SUMMARY_STATIC1 = this.APPEAL_SUMMARY_STATIC1sp;
  this.APPEAL_SUMMARY_STATIC2 = this.APPEAL_SUMMARY_STATIC2sp;
  this.APPEAL_SUMMARY_STATIC3 = this.APPEAL_SUMMARY_STATIC3sp;
  this.APPEAL_SUMMARY_STATIC4 = this.APPEAL_SUMMARY_STATIC4sp;
  this.APPEAL_SUMMARY_STATIC5 = this.APPEAL_SUMMARY_STATIC5sp;
  this.APPEAL_SUMMARY_STATIC6 = this.APPEAL_SUMMARY_STATIC6sp;
  this.APPEAL_SUMMARY_STATIC7 = this.APPEAL_SUMMARY_STATIC7sp;
  this.APPEAL_SUMMARY_STATIC8 = this.APPEAL_SUMMARY_STATIC8sp;
  this.APPEAL_SUMMARY_STATIC9 = this.APPEAL_SUMMARY_STATIC9sp;
  this.APPEAL_SUMMARY_STATIC10 = this.APPEAL_SUMMARY_STATIC10sp
 }
  }

  logInfo(e) {
    const id = e.currentTarget.id.split("_");
    const el = document.getElementById("collapse_" + id[1]);
    el.classList.toggle("show");

    const up = document.getElementById("up_" + id[1]);
    up.classList.toggle("cla1");
    up.classList.toggle("cla2");

    const dwn = document.getElementById("dwn_" + id[1]);
    dwn.classList.toggle("cla2");
    dwn.classList.toggle("cla1");
  }

}